import React from 'react'
import '../assets/css/energiaSolar.css';

import bg18 from '../assets/images/background/bg-18.png'


const Counter: React.FC = () => {
    return (
        <React.Fragment>
            <section className="mainSectionSolar">
                <div className="ContainerMainSolar">
                    <img src={bg18} />
                </div>
                <div className="ContainerMainSolar textEnergi">
                    <h1>ENERGIA SOLAR</h1>
                    <p>A empresa B2H está investindo em energia solar como parte de sua estratégia de sustentabilidade e economia de custos. A instalação de painéis solares personalizados está em andamento, visando reduzir a pegada de carbono e aumentar a independência energética da empresa. Este investimento não só beneficia o meio ambiente, mas também proporcionará economia a longo prazo. Com a conclusão da instalação e testes, a B2H estará gerando sua própria energia limpa, contribuindo para um futuro mais sustentável.</p>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Counter;