import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Contact from '../../components/Contact'

interface PricingItem {
    id: number;
    title: string;
    price: string;
    data?: string;
    features: string[];
    buttonText: string;
};

const pricingData: PricingItem[] = [
    {
        id: 1, title: 'Pro Pack', price: '199',
        features: [
            '100 MB Disk Space',
            '2 Subdomains',
            '5 Email Accounts',
            'Webmail Support',
            'Topics Covered',
        ], buttonText: 'Purchase now',
    },
    {
        id: 2, title: 'Standard Pack', price: '299', data: "Customer",
        features: [
            '100 MB Disk Space',
            '2 Subdomains',
            '5 Email Accounts',
            'Webmail Support',
            'Topics Covered',
            'Customer Support 24/7',
        ], buttonText: 'Purchase now',
    },
    {
        id: 3, title: 'Nerd Pack', price: '399',
        features: [
            '100 MB Disk Space',
            '2 Subdomains',
            '5 Email Accounts',
            'Webmail Support',
            'Topics Covered',
        ], buttonText: 'Purchase now',
    },
];


const Section1 = () => {
    return (
        <React.Fragment>

            {/* Contact */}
            <Contact />

            {/* Footer */}
            <footer className="section py-5 book-bg-footer">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="book-footer-box text-center">
                                <div className="mt-5">
                                    <ul className="list-inline book-footer-social mb-0">
                                        <li className="list-inline-item">
                                            <Link to="https://api.whatsapp.com/send?phone=5547991647908" className="">
                                                <i className="mdi mdi-whatsapp"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <p className="copy-right text-center mt-4 mb-0">
                                    © {new Date().getFullYear()} B2H
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    )
}

export default Section1