import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Counter from '../../components/Counter';
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

// Image
import book from "../../assets/images/book-home-img.png"

import user1 from "../../assets/images/users/user-1.jpg"
import user2 from "../../assets/images/users/user-2.png"
import user3 from "../../assets/images/users/user-3.png"
import user4 from "../../assets/images/users/skylimits.jpeg"
import user6 from "../../assets/images/users/user-6.png"
import user7 from "../../assets/images/users/user-7.png"

import parceiro1 from '../../assets/images/parceiros/parceiro-1.png';
import parceiro2 from '../../assets/images/parceiros/parceiro-2.png';
import parceiro3 from '../../assets/images/parceiros/parceiro-3.png';
import parceiro4 from '../../assets/images/parceiros/parceiro-4.png';
import parceiro5 from '../../assets/images/parceiros/parceiro-5.jpg';
import parceiro6 from '../../assets/images/parceiros/parceiro-6.png';
import parceiro7 from '../../assets/images/parceiros/parceiro-7.png';
import parceiro8 from '../../assets/images/parceiros/parceiro-8.png';
import parceiro9 from '../../assets/images/parceiros/parceiro-9.png';
import parceiro10 from '../../assets/images/parceiros/parceiro-10.jpeg';

import icon1 from '../../assets/images/IconsSobre/icon1.png';
import icon2 from '../../assets/images/IconsSobre/icon2.png';
import icon3 from '../../assets/images/IconsSobre/icon3.png';
import icon4 from '../../assets/images/IconsSobre/icon4.png';
import windIcon from '../../assets/images/parceiros/icons8-wind-50.png';
import icon6 from '../../assets/images/IconsSobre/icon6.png';
import icon8 from '../../assets/images/IconsSobre/icon8.png';
import icon10 from '../../assets/images/IconsSobre/icon10.png';
import icon11 from '../../assets/images/IconsSobre/icon11.png';
import icon12 from '../../assets/images/IconsSobre/icon12.png';
import icon13 from '../../assets/images/IconsSobre/icon13.png';

interface BookChapter {
    icon: string | React.ReactNode; title: string; textAbout: string;
}

interface ClientsData {
    id: number;
    image: string;
    name: string;
    designation: string;
    leftArrow: string;
}

const bookAboutData: BookChapter[] = [
    { icon: icon11, title: 'EFICIENCIA', textAbout: 'Na B2H, a eficiência é nossa marca registrada. Com uma equipe altamente qualificada e processos otimizados, garantimos resultados de alta qualidade em tempo hábil. Conte conosco para oferecer soluções rápidas e eficazes para todas as suas necessidades de manutenção.' },
    { icon: icon10, title: 'PROFISSIONALISMO', textAbout: 'Na B2H, profissionalismo é nossa prioridade. Nossa equipe é composta por especialistas dedicados que combinam experiência, habilidade e comprometimento para fornecer serviços de alta qualidade. Podemos lidar com qualquer desafio com precisão e confiabilidade, garantindo sua total satisfação.' },
    { icon: icon12, title: 'EXPERIÊNCIA', textAbout: 'Outra palavra que você pode usar para descrever a qualidade dos serviços da B2H é "Experiência". Isso destaca a expertise e o conhecimento acumulado ao longo do tempo pela equipe da empresa.' },
];

const chapterData: BookChapter[] = [
    { icon: icon1, title: 'Manutenção Elétrica', textAbout: 'Oferecemos serviços de Manutenção Elétrica com qualidade superior, garantindo que suas instalações permaneçam seguras e operacionais. Nossa equipe experiente e dedicada está pronta para resolver qualquer problema, proporcionando tranquilidade e confiança.' },
    { icon: icon2, title: 'Iluminação', textAbout: 'Transformamos espaços através de soluções inovadoras em iluminação. Nossa expertise em manutenção elétrica garante ambientes bem iluminados, seguros e eficientes, elevando o conforto e a produtividade.' },
    { icon: icon3, title: 'Hidráulica', textAbout: 'Conte com nossa expertise em hidráulica para soluções confiáveis, mantendo seu espaço funcional e seguro. Nossa equipe está preparada para resolver qualquer desafio e garantir seu conforto.' },
    { icon: icon4, title: 'Cameras de segurança', textAbout: 'Oferecemos soluções completas em segurança, incluindo instalação, manutenção e suporte para sistemas de câmeras, alarmes e interfones. Proteja seu espaço com nossa expertise em segurança eletrônica.' },
    { icon: windIcon, title: 'Refrigeração', textAbout: 'Garanta o funcionamento ideal de seus sistemas de refrigeração com nossos serviços especializados. Nossa equipe experiente em refrigeração está pronta para oferecer soluções confiáveis e eficientes para manter seus equipamentos em perfeito estado de funcionamento.' },
    { icon: icon13, title: 'Rede de ar comprimido e Termofusão', textAbout: 'Maximize a eficiência e confiabilidade de sua rede de ar comprimido com nossos serviços especializados em manutenção. Nossa equipe está preparada para garantir o desempenho ideal de seus sistemas, mantendo sua operação fluindo sem problemas.' },
];

const clientsData: ClientsData[] = [
    {
        id: 1,
        image: user1,
        name: 'TRANSBEN TRANSPORTES',
        designation: 'Logística',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 2,
        image: user2,
        name: 'DICAVE',
        designation: 'Concessionária',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 3,
        image: user4,
        name: 'SKYLIMIT',
        designation: 'Construtora & Incorporadora',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 4,
        image: user3,
        name: 'Benvenutti',
        designation: 'Confecção',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 5,
        image: user6,
        name: 'REDUX 32',
        designation: 'Combustivel',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 6,
        image: user7,
        name: 'Combutech',
        designation: 'Combustivel',
        leftArrow: 'pe-7s-angle-left-circle'
    },
];

const parceirosData: ClientsData[] = [
    {
        id: 1,
        image: parceiro1,
        name: 'AXOSEG',
        designation: 'Segurança',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 2,
        image: parceiro2,
        name: 'BRUSFER',
        designation: 'Ferramentas',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 3,
        image: parceiro3,
        name: 'WALPA',
        designation: 'Instaladora',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 4,
        image: parceiro4,
        name: 'LM',
        designation: 'Material de Construção',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 5,
        image: parceiro5,
        name: 'PREVENSUL',
        designation: 'Preventivos',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 6,
        image: parceiro6,
        name: 'KRAFT',
        designation: 'Elétrica',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 7,
        image: parceiro7,
        name: 'SANI COLOR',
        designation: 'Tintas e Revestimentos',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 8,
        image: parceiro8,
        name: 'FRIGELAR',
        designation: 'Refrigeração',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 9,
        image: parceiro9,
        name: 'CENTRAL DO AR',
        designation: 'Refrigeração',
        leftArrow: 'pe-7s-angle-right-circle'
    },
    {
        id: 10,
        image: parceiro10,
        name: 'MECZILKI',
        designation: 'Oficina Multimarcas',
        leftArrow: 'pe-7s-angle-right-circle'
    },
];

const renderClientIcon = (icon: string | React.ReactNode) => {
    // Verificando se o ícone é uma string ou um elemento React
    if (typeof icon === 'string') {
        return <img src={icon} className="img-fluid" alt="" />;
    } else {
        return icon; // Se for um elemento React, retorna diretamente
    }
};

const Section = () => {
    return (
        <React.Fragment>

            {/* Home */}
            <section className="section book-home" id="home">
                <div className="bg-overlay"></div>
                <div className="home-center">
                    <div className="home-desc-center">
                        <Container>
                            <Row className="align-items-center">
                                <Col lg={7}>
                                    <div className="book-home-content text-white mt-4">
                                        <h4 className="book-home-title text-uppercase line-height_1_4 letter-spacing_6">
                                            MANUTENÇÃO EFICIENTE PARA O SEU SUCESSO
                                        </h4>
                                        <p className="book-home-desc f-16 mt-4">
                                            Descubra uma solução completa para todos os seus problemas de manutenção com a equipe especializada da B2H, onde a transparência e a eficiência são garantidas em cada etapa do processo.
                                        </p>
                                        <h4 className="book-price mt-4 pt-3">
                                            <span className="f-20 pe-4">
                                                <del> </del>
                                            </span>

                                        </h4>
                                        <div className="mt-5 pt-3">
                                            <Link to="https://api.whatsapp.com/send?phone=5547991647908">
                                                <Button variant="custom" style={{ backgroundColor: '#1d3081', }}>Contato agora</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5}>
                                    <div className="book-home-img mt-4">
                                        <img src={book} className="img-fluid" alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>

            {/* About */}
            <section className="section book-about" id="about">
                <Container>
                    <Row>
                        {(bookAboutData || [])?.map((item: BookChapter, index: number) => (
                            <Col lg={4} key={index}>
                                <div className="book-about-box mt-4">
                                    <div className="book-about-icon">
                                        {renderClientIcon(item.icon)}
                                    </div>
                                    <h4 className="f-15 text-uppercase letter-spacing_2 mt-3">{item.title}</h4>
                                    <div className="book-about-border mt-3"></div>
                                    <p className="text-muted mt-3 mb-0">{item.textAbout}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>

                </Container>
            </section>

            <hr />

            {/* Services */}
            <section className="section" id="services">
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">Serviços Especializados em Manutenção</h2>
                                <p className="heading-title-desc text-muted mt-4">
                                    Descubra como nossos serviços abrangem todas as suas necessidades de manutenção e garantem o funcionamento ideal de seus sistemas.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 pt-3">
                        {(chapterData || [])?.map((item: BookChapter, index: number) => (
                            <Col lg={4} key={index}>
                                <div className="book-services-box text-center mt-4">
                                    <div className="book-services-icon">
                                        {renderClientIcon(item.icon)}
                                    </div>
                                    <h4 className="f-15 text-uppercase letter-spacing_2 mt-3">{item.title}</h4>
                                    <div className="book-services-border mt-3"></div>
                                    <p className="text-muted mt-3">{item.textAbout}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>

            {/* Counter */}
            <Counter />

            {/* Client */}

            <section className="section" id="client">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">CLIENTES</h2>
                                <p className="heading-title-desc text-muted mt-4">Alguns de nossos principais clientes.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Swiper className="mySwiper4" spaceBetween={5} pagination={true} modules={[Pagination]} style={{ height: '320px', }}>
                                {(clientsData || [])?.map((client: ClientsData) => (
                                    <SwiperSlide key={client.id}>
                                        <div className="item mx-2">
                                            <div className="book-client-box text-center mt-4">
                                                <div className="book-client-img mt-5">
                                                    <img src={client.image} className="img-fluid rounded-circle" alt="" />
                                                </div>
                                                <h5 className="f-15 text-uppercase letter-spacing_2 mt-4">{client.name}</h5>
                                                <p className="text-muted f-14 mb-0">{client.designation}</p>
                                                <br />
                                                <p className={client.leftArrow} style={{ fontSize: '30px' }}></p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section" id="client">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">PARCEIROS</h2>
                                <p className="heading-title-desc text-muted mt-4">Alguns de nossos principais parceiros.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 pt-3">
                        <Col lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Swiper className="mySwiper4" slidesPerView={3} spaceBetween={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(parceirosData || [])?.map((client) => (
                                    <SwiperSlide key={client.id}>
                                        <div className="item mx-2" key={client.id} style={{ height: '200px', }}>
                                            <div className="book-client-box text-center mt-4">
                                                <div className="book-client-img mt-5" style={{ height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={client.image} className="img-fluid rounded-circle" alt=""  />
                                                </div>
                                                <h5 className="f-15 text-uppercase letter-spacing_2 mt-4">{client.name}</h5>
                                                <p className="text-muted f-14 mb-0">{client.designation}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>

            <hr />

        </React.Fragment>
    )
}

export default Section