// pages
import Index2 from "../pages/Applanding/Index2"
import Index6 from "../pages/Booklanding/Index6"

const routes = [

    // App-Landing
    { path: "/app-landing", component: <Index2 /> },

    // Book-Landing
    { path: "/", component: <Index6 /> },

]

export { routes };